import React, { Component } from 'react';


//Import libreria de conversion de fechas
import Moment from 'react-moment';
import moment from 'moment';

//Import para renderizar loading
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "./LoadingIndicator.js";

//import de la URL de la api
import api from "./config/config.js"

//import para estilizar mensajes tipo NOTIFICACIONES
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Import Icono pdf
import pdf from "./Imagenes/pdf.png"

//Import  procesador de XML
import XMLParser from 'react-xml-parser';
//Import para manejar el formato de los numeros
import NumberFormat from 'react-number-format';
import $ from 'jquery';

//import de iconos
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class Facturas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha_hasta: moment().format('YYYY-MM-DD'),
            fecha_desde: moment().subtract(7, "days").format('YYYY-MM-DD'),
            items: [],
            err: '',
            detalle: [],
            pago: [],
            selected: [],
            mostrar: '',
            pdf: null,
            //ingreso: moment(new Date()).format('YYYY-MM-DD,  h:mm:ss a'),
            p_token: localStorage.getItem("token"),

        }

        this.cambioFechaDesde = this.cambioFechaDesde.bind(this);
        this.cambioFechaHasta = this.cambioFechaHasta.bind(this);


    }//cierra el constructor

    cambioFechaHasta(e) {
        this.setState({
            fecha_hasta: e.target.value,
            err: ''
        })

    }



    resaltarFila = () =>
        $('#tableFactura tr').click(function (e) {
            $('#tableFactura tr').removeClass('highlighted');
            $(this).addClass('highlighted');
        });


    cambioFechaDesde(e) {
        this.setState({
            fecha_desde: e.target.value,
            err: ''
        })
    }


    borrarDatos = () => {
        if (this.state.items !== []) {
            this.setState({ items: "", detalle: "", pago: null, importe: "", selected: "", mostrar: "" })
        }
    }

    handleSubmit = async e => {
        e.preventDefault()

        if (this.state.fecha_desde === '') {
            this.setState({ err: "***Seleccione el campo Fecha Desde***" })
        } else if (this.state.fecha_hasta === '') {
            this.setState({ err: "***Seleccione el campo Fecha Hasta***" })
        } else if (moment(this.state.fecha_desde).isAfter(this.state.fecha_hasta) === true) {
            this.setState({ err: "***El campo fecha desde es incorrecto***" })
        }
        else if (moment(this.state.fecha_hasta).isAfter(moment()) === true) {
            this.setState({ err: "***El campo fecha hasta es incorrecto***" })
        } else {
            this.borrarDatos()
            var urlencoded = new URLSearchParams();
            urlencoded.append("fecha_desde", this.state.fecha_desde);
            urlencoded.append("fecha_hasta", this.state.fecha_hasta);
            // urlencoded.append("fecha_desde", "2016-03-02");
            // urlencoded.append("fecha_hasta", "2020-04-04");
            try {
                let config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.state.p_token
                    },
                    body: urlencoded
                }
                let res = await trackPromise(fetch(`${api}facturas/consulta`, config))
                let json = await res.json()


                if (json.status === 500) {
                    this.setState({ err: json.message })

                } else if ($.isEmptyObject(json.facturas)) {
                    this.notify()
                    this.borrarDatos()
                }
                else {
                    this.setState({
                        items: json.facturas
                    })
                    console.log(this.state.items)
                    this.setState({ err: "" })
                    this.resaltarFila()
                    return json;
                }

            } catch (error) {
                this.setState({ err: error })

            }
        }
    }

    notify = () =>

        toast.info('No hay movimientos en el rango de fecha indicado', {
            position: "top-center",
            toastId: 4340,
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: false,

            pauseOnFocusLoss: true


        })



    downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "factura.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.setState({ err: "" })
    }


    convertirPDF = (result) => {

        //console.log(result)
        this.setState({ pdf: result });
        //console.log(this.state.pdf)
        var xml = new XMLParser().parseFromString(this.state.pdf);
        //console.log(xml);
        const resultado = xml.getElementsByTagName("return")

        if (resultado[0].value.length !== 0) {
            this.setState({ pdf: resultado[0].value })
            this.downloadPDF(this.state.pdf)
        } else {
            this.setState({ err: "Error al descargar PDF" })
        }
    }


    render() {
        return (

            <div>
                <div>

                    <form onSubmit={this.handleSubmit}  >
                        <table className="Form-table">
                            <tr>
                                <td>  Desde:  </td>
                                <td > <input id="fecha_desde" type="date" name="fecha_desde" value={this.state.fecha_desde} onChange={this.cambioFechaDesde} /></td>
                                <td >  Hasta: </td>
                                <td > <input id="fecha_hasta" type="date" name="fecha_hasta" value={this.state.fecha_hasta} onChange={this.cambioFechaHasta} /></td>
                                <td><button className="ml-2" id="BotonConsultar" onSubmit={this.handleSubmit} style={{ padding: 0, border: 0, background: "none" }} ><FontAwesomeIcon icon={faSearch} /> </button></td>
                                <td><strong><label className="labelErr" style={{ color: "red" }} align="center">{this.state.err}</label></strong></td>
                            </tr>
                        </table>
                    </form >

                    <LoadingIndicator />
                    <div className="mt-5 mx-auto" id="divSticky" style={{ maxWidth: "95%" }}>

                        <table className="table table-striped" id="tableFactura" style={{ width: "100%" }}> {/* any table */}
                            <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }}  >
                                <tr >
                                    <th className="text-center" >Fecha</th>
                                    <th className="text-center">Número</th>
                                    <th className="text-center">Total</th>
                                    <th className="text-center">Vendedor</th>
                                    <th className="text-center">Sucursal</th>
                                    <th className="text-center" >C.A.E</th>
                                    <th className="text-center">PDF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.items ?
                                    this.state.items.map((item, i) =>
                                        <tr
                                            onClick={async () => {

                                                console.log("esto anda")
                                                var urlencoded = new URLSearchParams();
                                                urlencoded.append("factura_id", item.factura_id);
                                                let config = {
                                                    method: 'POST',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-type': 'application/x-www-form-urlencoded',
                                                        'Authorization': 'Bearer ' + this.state.p_token
                                                    },
                                                    body: urlencoded
                                                }
                                                let res = await trackPromise(fetch(`${api}facturas/consultalinea`, config))
                                                let jsonLinea = await res.json();
                                                res = await trackPromise(fetch(`${api}facturas/consultapago`, config));
                                                let jsonPago = await res.json();
                                                this.setState({ selected: jsonLinea.factura_lineas, pago: jsonPago.factura_pago })
                                                this.setState({ mostrar: item.nro_factura })


                                            }

                                            }>
                                            <td className="text-center" ><Moment format="DD/MM/YYYY">{item.fecha}</Moment></td>
                                            <td className="text-center" >{item.nro_factura}</td>
                                            <td className="text-center" ><NumberFormat value={item.total} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                            <td className="text-center" >{item.vendedor}</td>
                                            <td className="text-center" >{item.sucursal}</td>
                                            <td className="text-center" >{item.cae}</td>
                                            <th className="text-center" >{item.foto_id !== null ?
                                                <input type="image" onClick={async () => {

/* 

                                                    var myHeaders = new Headers();
                                                    myHeaders.append("Content-Type", "text/xml");

                                                    var raw = `
                                                    \n<Envelope xmlns=\"http://schemas.xmlsoap.org/soap/envelope/\">
                                                    \n    <Body>
                                                    \n        <obtenerPDF xmlns=\"http://yugoo.com.ar/wsdl\">
                                                    \n            <password>0bt3n3rPDF2020</password>
                                                    \n            <id>74102</id>
                                                    \n            <path>/home/ws_imagen/2020/2/28</path>
                                                    \n        </obtenerPDF>
                                                    \n    </Body>
                                                    \n</Envelope>
                                                    \n`;
                                                    
                                                    var requestOptions = {
                                                        method: 'POST',
                                                        headers: myHeaders,
                                                        body: raw,
                                                        redirect: 'follow'
                                                    };

                                                    fetch("https://ws.vicaneumaticos.com:443/servicio_web/yugoo_ws_imagen.php", requestOptions)
                                                        .then(response => response.text())
                                                        .then(result => console.log(result))
                                                        .catch(error => console.log('error', error)); */
                                                      var p_foto_id = item.foto_id //"111111" //
                                                      var p_path = item.path_foto //
                                                      var data = `<Envelope xmlns=\"http://schemas.xmlsoap.org/soap/envelope/\">\n    <Body>\n        <obtenerPDF xmlns=\"http://yugoo.com.ar/wsdl\">\n            <password>0bt3n3rPDF2020</password>\n            <id>${p_foto_id}</id>\n            <path>${p_path}</path>\n        </obtenerPDF>\n    </Body>\n</Envelope>`;
  
                                                      var xhr = new XMLHttpRequest();
                                                      xhr.withCredentials = false;
  
  
                                                      xhr.onload = () => {
                                                          if (xhr.status === 200) {
                                                              var result = xhr.responseText
                                                              this.convertirPDF(result)
                                                          } else {this.setState({ err: xhr.statusText })}
  
                                                      }
  
  
                                                      xhr.open("POST", "https://ws.vicaneumaticos.com/servicio_web/yugoo_ws_imagen.php/");
                                                      xhr.setRequestHeader("SOAPAction", "\"urn:YugooWsdl#obtenerPDF\"");
  
                                                      xhr.setRequestHeader("Content-Type", "text/xml");
  
                                                      xhr.send(data);

                                                }} src={pdf} />
                                                : "No disponible"}</th>
                                        </tr>

                                    )

                                    : null
                                }


                            </tbody>
                        </table >

                    </div >

                    <div className="ml-4" > <label className="ml-3" >Items de la Factura : {this.state.mostrar}</label></div>

                    <div class="row">
                        <div className="col-8  mx-auto" id="Detalle"> {/* container width optional */}

                            <table className="table table-striped" id="tableDetalleFactura" >
                                <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }} >
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="text-center">Código</th>
                                        <th className="text-center">Descripción</th>
                                        <th className="text-center">P.U c/IVA</th>
                                        <th className="text-center">Desc/Rec</th>
                                        <th className="text-center">IVA</th>
                                        <th className="text-center">SubTotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.selected.length > 0 ?
                                        this.state.selected.map((item, i) =>
                                            <tr>
                                                <td className="text-center">{item.cantidad}</td>
                                                <td className="text-center">{item.cod_bar}</td>
                                                <td className="text-center">{item.descripcion}</td>
                                                <td className="text-center"><NumberFormat value={item.precio} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                                <td className="text-center"><NumberFormat value={item.bonificacion} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                                <td className="text-center">{item.iva}%</td>
                                                <td className="text-center"><NumberFormat value={item.subtotal} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>

                                            </tr>
                                        )

                                        : null
                                    }

                                </tbody>
                            </table>

                        </div>

                        <div className="col-3 mx-auto" id="tablePagoFactura">

                            <table className="table  table-striped" >
                                <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }}>
                                    <tr>
                                        <th className="text-center">Forma de Pago</th>
                                        <th className="text-center">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.pago !== null ?
                                        this.state.pago.map((item, i) =>
                                            <tr>
                                                <td className="text-center" >{item.nombre}</td>
                                                <td className="text-center" ><NumberFormat value={item.importe} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                            </tr>
                                        )

                                        : <tr>
                                            <td ></td>
                                            <td ></td>
                                        </tr>
                                    }

                                </tbody>
                            </table>

                        </div>
                    </div>

                </div >
            </div >


        );
    }
};


export default Facturas;

