import React, { Component } from 'react';

//import de libreria para manejar fechas
import Moment from 'react-moment';
import moment from 'moment';

//Import para renderizar loading
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "./LoadingIndicator.js";
//import de la URL de la api
import api from "./config/config.js";

//import para estilizar mensajes tipo NOTIFICACIONES
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Import para manejar el formato de los numeros
import NumberFormat from 'react-number-format';
import $ from 'jquery';


//import de iconos
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class Recibos extends Component {
    constructor() {
        super();
        this.state = {
            fecha_hasta: moment().format('YYYY-MM-DD'),
            fecha_desde: moment().subtract(7, "days").format('YYYY-MM-DD'),
            items: [],
            pago: null,
            detalle: [],
            err: '',
            selected: [],
            mostrar: '',
            token: localStorage.getItem("token")
        }

        this.cambioFechaDesde = this.cambioFechaDesde.bind(this);
        this.cambioFechaHasta = this.cambioFechaHasta.bind(this);


    }

    cambioFechaHasta(e) {
        this.setState({
            fecha_hasta: e.target.value,
            err: ''
        })
    }


    cambioFechaDesde(e) {
        this.setState({
            fecha_desde: e.target.value,
            err: ''
        })
    }


    borrarDatos = () => {
        if (this.state.items !== []) {
            this.setState({ items: "", pago: null, detalle: "", selected: null, mostrar: "" })
        }
    }

    handleSubmit = async e => {
        e.preventDefault()

        if (this.state.fecha_desde === '') {
            this.setState({ err: "***Seleccione el campo Fecha Desde***" })
        } else if (this.state.fecha_hasta === '') {
            this.setState({ err: "***Seleccione el campo Fecha Hasta ***" })
        } else if (moment(this.state.fecha_desde).isAfter(this.state.fecha_hasta) === true) {
            this.setState({ err: "***El campo fecha desde es incorrecto***" })
        }
        else if (moment(this.state.fecha_hasta).isAfter(moment()) === true) {
            this.setState({ err: "***El campo fecha hasta es incorrecto***" })
        } else {
            this.borrarDatos()
            var urlencoded = new URLSearchParams();
            urlencoded.append("fecha_desde", this.state.fecha_desde);
            urlencoded.append("fecha_hasta", this.state.fecha_hasta);
            try {
                let config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    body: urlencoded
                }
                let res = await trackPromise(fetch(`${api}recibos/consulta`, config))
                let json = await res.json()
                console.log()
                if (json.status === 500) {
                    this.setState({ err: json.message })

                } else if ($.isEmptyObject(json.recibos)) {
                    this.notificacion()
                    this.borrarDatos()
                }
                else {

                    this.setState({
                        items: json.recibos
                    })
                    console.log(this.state.items)
                    this.setState({ err: "" })
                    this.resaltarFila()
                    return json;
                }
            } catch (error) {
                console.log(error)

            }
        }
    }

    notificacion = () => toast.info('No hay movimientos en el rango de fecha indicado', {
        position: "top-center",
        toastId: 3000,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        pauseOnFocusLoss: true,

    });

    resaltarFila = () =>
        $('#tableRes tr').click(function (e) {
            $('#tableRes tr').removeClass('highlighted');
            $(this).addClass('highlighted');
        });


    render() {
        return (
            <div >
                <div>
                    <form onSubmit={this.handleSubmit}  >
                        <table className="Form-table">
                            <tr>
                                <td>  Desde:  </td>
                                <td > <input id="fecha_desde" type="date" name="fecha_desde" value={this.state.fecha_desde} onChange={this.cambioFechaDesde} /></td>
                                <td >  Hasta: </td>
                                <td > <input id="fecha_hasta" type="date" name="fecha_hasta" value={this.state.fecha_hasta} onChange={this.cambioFechaHasta} /></td>
                                <td><button className="ml-2" id="BotonConsultar" onSubmit={this.handleSubmit} style={{ padding: 0, border: 0, background: "none" }} ><FontAwesomeIcon icon={faSearch} /> </button></td>
                                <td><strong><label className="labelErr" style={{ color: "red" }} align="center">{this.state.err}</label></strong></td>
                            </tr>
                        </table>
                    </form >
                    <LoadingIndicator />

                    <div className="mt-5 mx-auto" id="divSticky" style={{ maxWidth: "90%" }}>

                        <table className="table table-striped table-responsive" id="tableRes" style={{ width: "100%" }}> {/* any table */}
                            <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }}>
                                <tr>
                                    <th className="text-center">Fecha</th>
                                    <th className="text-center">Número</th>
                                    <th className="text-center">Total</th>
                                    <th className="text-center">Usuario</th>
                                    <th className="text-center">Cobrador</th>
                                    <th className="text-center">Sucursal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.items ?
                                    this.state.items.map((item, i) =>
                                        <tr
                                            onClick={async () => {
                                                var urlencoded = new URLSearchParams();
                                                urlencoded.append("recibo_id", item.recibo_id);
                                                let config = {
                                                    method: 'POST',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-type': 'application/x-www-form-urlencoded',
                                                        'Authorization': 'Bearer ' + this.state.token
                                                    },
                                                    body: urlencoded
                                                }
                                                let res = await trackPromise(fetch(`${api}recibos/consultalinea`, config))
                                                let jsonLinea = await res.json();
                                                res = await trackPromise(fetch(`${api}recibos/consultapago`, config));
                                                let jsonPago = await res.json();
                                                this.setState({ selected: jsonLinea.recibo_lineas, pago: jsonPago.recibo_pago })
                                                this.setState({ mostrar: item.nro_recibo })

                                            }

                                            }>

                                            <td className="text-center"><Moment format="DD/MM/YYYY">{item.fechahora}</Moment></td>
                                            <td className="text-center">{item.nro_recibo}</td>
                                            <td className="text-center"><NumberFormat value={item.total} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                            <td className="text-center">{item.usuario}</td>
                                            <td className="text-center">{item.cobrador}</td>
                                            <td className="text-center">{item.sucursal}</td>
                                        </tr>

                                    ) : null
                                }
                            </tbody>
                        </table>

                    </div>


                    <div className="row">

                        <div className="col-6   mx-auto">
                            <label className="LabelDocRes">Items del Recibo : {this.state.mostrar}</label>
                        </div>
                        <div className="col-4 mx-auto">
                            <label className="LabelDoc2">Pago del Recibo : {this.state.mostrar}</label>
                        </div>
                    </div>




                    <div className="row">

                        <div className="col-6  mx-auto">
                            <table className="table table-striped" id="itemRecibo">
                                <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }}>
                                    <tr>
                                        <th className="text-center"  >Descripcion</th>
                                        <th className="text-center" >Importe</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.selected !== null ?
                                        this.state.selected.map((item, i) =>
                                            <tr>
                                                <td className="text-center" >{item.descripcion}</td>
                                                <td className="text-center" ><NumberFormat value={item.importe} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                            </tr>
                                        )

                                        : <tr>
                                            <td ></td>
                                            <td ></td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>


                        <div className="col-4 mx-auto">
                            <table className="table table-striped" id="itemRecibo">
                                <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }}>
                                    <tr>
                                        <th className="text-center" >Forma de Pago</th>
                                        <th className="text-center">Importe</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.pago !== null ?
                                        this.state.pago.map((item, i) =>
                                            <tr>
                                                <td className="text-center" >{item.nombre}</td>
                                                <td className="text-center" ><NumberFormat value={item.importe} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                            </tr>
                                        )

                                        : <tr>
                                            <td ></td>
                                            <td ></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        );

    }

};


export default Recibos;
