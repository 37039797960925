import React, { Component } from 'react';

//Import libreria para manejar formato fecha
import Moment from 'react-moment';
import moment from 'moment';
//Import para renderizar loading
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "./LoadingIndicator.js";

//import para estilizar mensajes tipo NOTIFICACIONES
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import de la URL de la api
import api from "./config/config.js"

//Import para manejar el formato de los numeros
import NumberFormat from 'react-number-format';
import $ from 'jquery';


//import de iconos
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class OrdenesTrabajo extends Component {
  constructor() {
    super();
    this.state = {
      fecha_hasta: moment().format('YYYY-MM-DD'),
      fecha_desde: moment().subtract(7, "days").format('YYYY-MM-DD'),
      items: [],
      detalle: null,
      err: '',
      trabajosDistintos: [],
      selected: [],
      token: localStorage.getItem("token")
    }

    this.cambioFechaDesde = this.cambioFechaDesde.bind(this);
    this.cambioFechaHasta = this.cambioFechaHasta.bind(this);
   
  }//cierra el constructor

  cambioFechaHasta(e) {
    this.setState({
      fecha_hasta: e.target.value,
      err: ''
    })
  }

  cambioFechaDesde(e) {
    this.setState({
      fecha_desde: e.target.value,
      err: ''
    })
  }


  resaltarFila = ()=>
  $('#tableTrabajos tr').click(function(e) {
    $('#tableTrabajos tr').removeClass('highlighted');
    $(this).addClass('highlighted');
});

borrarDatos= () =>
{
  if(this.state.items !==[])
  {
    this.setState({ items:  null, detalle: null,trabajosDistintos: [], selected: []})
  }
}

  handleSubmit = async e => 
  {
    e.preventDefault()
    if (this.state.fecha_desde === '') {
      this.setState({ err: "***Seleccione el campo Fecha Desde***" })
    } else if (this.state.fecha_hasta === '') {
      this.setState({ err: "***Seleccione el campo Fecha Hasta ***" })
    } else if (moment(this.state.fecha_desde).isAfter(this.state.fecha_hasta) === true)
    {
      this.setState({ err: "***El campo fecha desde es incorrecto***" })
    } 
    else if (moment(this.state.fecha_hasta).isAfter(moment()) === true)
    {
      this.setState({ err: "***El campo fecha hasta es incorrecto***" })
    } 
    else {
      this.borrarDatos()
      var urlencoded = new URLSearchParams();
      urlencoded.append("fecha_desde", this.state.fecha_desde);
      urlencoded.append("fecha_hasta", this.state.fecha_hasta);
      // urlencoded.append("fecha_desde", "2016-03-02");
      // urlencoded.append("fecha_hasta", "2020-04-04");
      try {
        
        let config = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + this.state.token

          },
          body: urlencoded
        }
       
        let res =  await trackPromise( fetch(`${api}trabajos/consulta`, config))
        let json = await res.json()
        if (json.status === 500)  {
          this.setState({err: json.message})
         
        }else if  ($.isEmptyObject(json.trabajos))
        {
          this.notificar()
          this.borrarDatos()
        }else {
          this.setState({
            items: json.trabajos
          })
         
          //this.ordenarTrabajos(json.trabajos)
          this.setState({ err: "" })
          this.resaltarFila() 
          return json;
        }
      }catch (error) {
        console.log(error)

     }
  }
}

 /*    ordenarTrabajos(trabajos) 
    {
      let trabajosOrdenados = trabajos.sort()
      this.setState({ items: trabajosOrdenados })
      console.log("trabajos ordenados")
      console.log(this.state.items)
      this.separarTrabajos();
    }
    separarTrabajos() 
    {
      let trabajosUnicos = []
      let index = this.state.items.length - 1
      let id_inicial = this.state.items[index].orden_id
      for (var i = 0; i < this.state.items.length; i++) {
        if (this.state.items[i].orden_id !== id_inicial) {
          trabajosUnicos[i] = this.state.items[i]
          id_inicial = trabajosUnicos[i].orden_id
        }else if(id_inicial === this.state.items[this.state.items.length-1].orden_id) {
          trabajosUnicos[0] = this.state.items[0]
        }
        else if (this.state.items.length === 1) {
          trabajosUnicos = this.state.items
        }
      }
      this.setState({ trabajosDistintos: trabajosUnicos })
      return trabajosUnicos;
    } */

    notificar = () =>
    
      toast.info('No hay movimientos en el rango de fecha indicado', {
      position: "top-center",
      toastId: 4340,
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss:true
      
  });
  
    
  render() {

    return (
      <div>
        <div>
        <form onSubmit={this.handleSubmit}  >
                        <table className="Form-table">
                            <tr>
                                <td>  Desde:  </td>
                                <td > <input id="fecha_desde" type="date" name="fecha_desde" value={this.state.fecha_desde} onChange={this.cambioFechaDesde} /></td>
                                <td >  Hasta: </td>
                                <td > <input id="fecha_hasta" type="date" name="fecha_hasta" value={this.state.fecha_hasta} onChange={this.cambioFechaHasta} /></td>
                                <td><button className="ml-2" id="BotonConsultar" onSubmit={this.handleSubmit} style={{ padding: 0, border: 0, background: "none" }} ><FontAwesomeIcon icon={faSearch} /> </button></td>
                                <td><strong><label className="labelErr" style={{ color: "red" }} align="center">{this.state.err}</label></strong></td>
                            </tr>
                        </table>
                    </form >
          <LoadingIndicator />
          <div className=" mt-5 mx-auto" id="divSticky" style={{maxWidth:"90%" }}> 
            <table className="table table-striped" id="tableTrabajos" style={{ width: "100%" }}> 
                <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }} >
                <tr>
                      <th className="text-center">Estado</th>
                      <th className="text-center">Fecha</th>
                      <th className="text-center">Compromiso</th>
                      <th className="text-center">Número</th>
                      <th className="text-center">Sucursal</th>
                      <th className="text-center">Vehículo</th>
                      <th className="text-center">Total</th>
                      <th className="text-center">Factura</th>
                   </tr>
                </thead>
                <tbody >
                { this.state.items ?
                    this.state.items.map((item, i) =>
                    <tr
                    onClick={ async () => {
                      var urlencoded = new URLSearchParams();
                      urlencoded.append("orden_id", item.id);
                      let config = {
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json',
                          'Content-type': 'application/x-www-form-urlencoded',
                          'Authorization': 'Bearer ' + this.state.token
                        },
                        body: urlencoded
                      }
                      let res = await trackPromise (fetch(`${api}trabajos/consultalinea`, config))
                      let jsonLinea = await res.json();
                      this.setState({ selected: jsonLinea.trabajos})
                      
                    }

                    }>
                        <td className="text-center"   >{item.estado}</td>
                        <td className="text-center"   ><Moment format="DD/MM/YYYY HH:mm">{item.fecha_hora}</Moment></td>
                        <td className="text-center"   ><Moment format="DD/MM/YYYY">{item.fecha_compromiso}</Moment></td>
                        <td className="text-center"   >{item.nro_orden}</td>
                        <td className="text-center"   >{item.nombre}</td>
                        <td className="text-center"   >{item.vehiculo}</td>
                        <td className="text-center"   ><NumberFormat value={item.total_orden} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                        <td className="text-center"   >{item.nro_factura}</td>
                      
                      </tr>
                    ): null
                    
                  }

                </tbody>
              </table>
          </div>
      <label label align="center" style={{ marginLeft: "5%", marginTop:"1%" }}>Detalle de la orden</label>
         <div className="mt-4 ml-5 mx-auto"  style={{ maxWidth: "90%" }}> 
           <table className="table Sticky" id="tableTrabajos" style={{ width: "100%" }}>
                <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }} >
                <tr>
                  <th className="text-center"># </th>
                  <th className="text-center">Código </th>
                  <th className="text-center">Descripción</th>
                  <th className="text-center">Presupuesto</th>
                  <th className="text-center">Responsable</th>
                  <th className="text-center">P.U. c/IVA</th>
                  <th className="text-center">Desc/Rec</th>
                  <th className="text-center">Subtotal</th>
                </tr>
                </thead>
                <tbody >
                {this.state.selected.length > 0 ?
                  this.state.selected.map((item, i) =>
                    <tr>
                      <td className="text-center">{item.cantidad}</td>
                      <td className="text-center">{item.codigo}</td>
                      <td className="text-center">{item.descripcion}</td>
                      <td className="text-center">{item.presupuesto}</td>
                      <td className="text-center">{item.responsable}</td>
                      <td className="text-center"><NumberFormat value={item.precio} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                      <td className="text-center"><NumberFormat value={item.desc_rec} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                      <td className="text-center"><NumberFormat value={item.subtotal} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                    </tr>
                  )

                  : null

                }

                </tbody>
              </table>
           </div>
        </div>
      </div>
    );
  }
};


export default OrdenesTrabajo;

