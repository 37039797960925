import React, { Component } from 'react';
//import Menu from "./MainMenu2.js"
//Import la url de la api
import api from "./config/config.js"
//Import de estilos CSS
import "./Estilos/Login.css"

import {Redirect } from 'react-router-dom';

//import foto header
import vicaHeader from "./Imagenes/header-vica-logo.jpg"

class Login extends Component {
  constructor() {
    super();

    this.state = {
      usuario: '',
      password: '',
      items: [],
      err: ''

    }
    this.cambioUsuario = this.cambioUsuario.bind(this);
    this.cambioPassword = this.cambioPassword.bind(this);
    //this.cambioItems = this.cambioItems.bind(this);
  }


  cambioUsuario(e) {
    this.setState({
      usuario: e.target.value
    })
  }
  cambioPassword(e) {
    this.setState({
      password: e.target.value
    })
  }

  redirect(p_token) {

    let p_forms = this.state.items.usuario[0].formularios
    let p_apellido = this.state.items.usuario[0].cliente_apellido
    let p_nombre = this.state.items.usuario[0].cliente_nombre
    let p_usuario = this.state.items.usuario[0].usuario
    localStorage.setItem("forms", p_forms)
    localStorage.setItem("token", p_token)
    localStorage.setItem("apellido",p_apellido)
    localStorage.setItem("nombre",p_nombre)
    localStorage.setItem("usuario",p_usuario)

    if (p_token !== '' && p_forms !== "") {
      //return <Menu />
      this.props.history.push(`/Menu`)

      // this.props.history.replace(`/MainMenu/${token}`)
    } else { return console.log("error") }
  }


  handleSubmit = async e => {
    e.preventDefault();
    if (this.state.usuario === '') {
      this.setState({ err: "***Campo Usuario Vacio***" })
    } else if (this.state.password === '') {
      this.setState({ err: "***Campo Password Vacio***" })
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("usuario", this.state.usuario);
      urlencoded.append("password", this.state.password);
      try {
        var config = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };

        let res = await fetch(`${api}usuarios/login`, config)
        let json = await res.json()
        if (json.auth) {
          this.setState({
            items: json
          })
          let p_token = this.state.items.token;
          this.redirect(p_token)
        }
        else { this.setState({ err: json.message }) }
      }
      catch (error) {
        this.setState({ err: "Error al conectar" })

      }
    }
  }
  
  VerificarSesion = () =>
    {
      if ((localStorage.getItem("token") === null ) || (localStorage.getItem("forms") === null))
      {
        
        return <Redirect to ="/" push={true}/>
      }
      else {
       this.props.history.replace("/Menu")
      }
    }  


  render() {

    return (
      <div className="login-dark">
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"></link>
         {this.VerificarSesion()}  
        <div >
          <form onSubmit={this.handleSubmit}>
            <h2 className="sr-only">Login Form</h2>
            <div className="illustration"><img alt="vica-header" src={vicaHeader} /></div>
            <label className="LabelErr" >{this.state.err}</label>
            <div className="form-group"><input className="form-control" type="text" name="text" placeholder="Usuario" onChange={this.cambioUsuario} /></div>
            <div className="form-group"><input className="form-control" type="password" name="password" placeholder="Password" onChange={this.cambioPassword} /></div>
            <div className="form-group"><button className="btn btn-primary btn-block" onSubmit={this.handleSubmit}>Log In</button></div>
          </form>
        </div>

      </div>
    );
  }
}
// }
export default Login;