import React, { Component } from 'react';

import { faBars, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Estilos/Menu.css"

//Logo de header
import LogoPanel from "./Imagenes/logo-panel.png"
import vicaLogo from "./Imagenes/vica.png"
import { Tab, TabList, TabPanel, TabProvider } from 'react-web-tabs';

//Forms
import EstadoCuenta from "./EstadoCuenta.js"
import Presupuestos from "./Presupuestos.js"
import OrdenesTrabajo from './OrdenesTrabajo';
import Recibos from './Recibos';
import Login from './Login.js'
//import Login from "./Login.js"
import { ToastContainer } from 'react-toastify';

//import de fecha
import moment from 'moment';


/*Utilidades */
import $ from 'jquery';
import "./Estilos/bootstrap/css/bootstrap.min.css"
import Facturas from './Facturas';



class Menu extends Component {
    constructor() {
        super();
        this.state = {
            forms: localStorage.getItem("forms"),
            selected: null
        }
    }

    addSidebar = () => {
        $("body").toggleClass("sb-sidenav-toggled");

    }

    //Manejo de sesion de usuario expirada, por defento son 1200000 ms = 20 minutos

    isExpired = () => {
        setTimeout(() => {
            this.setState({ p_token: localStorage.removeItem("token") })
            this.props.history.push("/")
        }, 1800000);
    }


    SelectedTab = () => {
        let TodosForm = ['AG_Recibo', 'AG_OrdenTrabajo', 'AG_Presupuesto', 'AG_Factura', 'AG_EstadoCuenta'];
        let formsVisibles = this.state.forms.split(",")
        //let formsVisibles = ['AG_OrdenTrabajo', 'AG_Presupuesto', 'AG_Factura']
        //let formsVisibles = ['AG_OrdenTrabajo', 'AG_Presupuesto', 'AG_Factura']
        TodosForm.sort()
        formsVisibles.sort()


        let j = 0

        if (formsVisibles.includes('AG_EstadoCuenta') === true) {
            return "vertical-tab-one"
        }
        else if (formsVisibles.includes('AG_Factura') === true) {
            return "vertical-tab-two"
        }
        else if (formsVisibles.includes('AG_Presupuesto') === true) {
            return "vertical-tab-three"
        }
        else if (formsVisibles.includes('AG_Recibo') === true) {
            return "vertical-tab-four"
        } else if (formsVisibles.includes('AG_OrdenTrabajo') === true) {
            return "vertical-tab-five"
        }
    }
    cerrarSesion = () => {

        if ((window.confirm("Desea Cerrar Sesion?"))) {
            localStorage.removeItem("usuario")
            localStorage.removeItem("apellido")
            localStorage.removeItem("token")
            localStorage.removeItem("forms")
            localStorage.removeItem("nombre")
            return this.props.history.push("/")

        }
    }

    verForms() {
         let TodosForm = ['AG_Recibo', 'AG_OrdenTrabajo', 'AG_Presupuesto', 'AG_Factura', 'AG_EstadoCuenta'];
        let formsVisibles = this.state.forms.split(",")
         //let formsVisibles =  ['AG_Recibo', 'AG_OrdenTrabajo', 'AG_Presupuesto', 'AG_Factura', 'AG_EstadoCuenta'];
        //let formsVisibles = ['AG_OrdenTrabajo', 'AG_Presupuesto', 'AG_Factura']
        TodosForm.sort()
        formsVisibles.sort()
        let formsOcultos = []
        let j = 0

        for (let i = 0; i < TodosForm.length; i++) {
            if (formsVisibles.includes(TodosForm[i]) === false) {
                formsOcultos[j] = TodosForm[i]
                j++
            }
        }


        if (formsOcultos !== []) {
            if (formsOcultos.includes('AG_Recibo') === true) {
                $(document).ready(function () {
                    $(".TabRecibo").hide();
                })
            }
            if (formsOcultos.includes('AG_OrdenTrabajo') === true) {
                $(document).ready(function () {
                    $(".TabOT").hide();
                })
            }
            if (formsOcultos.includes('AG_Presupuesto') === true) {
                $(document).ready(function () {
                    $(".TabPres").hide();
                })
            }
            if (formsOcultos.includes('AG_Factura') === true) {
                $(document).ready(function () {
                    $(".TabFact").hide();
                })
            }
            if (formsOcultos.includes('AG_EstadoCuenta') === true) {
                $(document).ready(function () {
                    $(".TabEst").hide();
                })
            }
        }



    }

    render() {
        if (this.state.p_token === "undefined") {
            return <Login />
        } else {
            return (

                <div className="sb-nav-fixed" style={{ backgroundColor: 'white' }} onLoad={this.isExpired()}>
                    {this.verForms()}
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"></link>
                    <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar
                        newestOnTop={true}
                        closeOnClick={true}
                        rtl={true}
                        pauseOnFocusLoss={true}
                        draggable
                        pauseOnHover
                        limit={1}
                    />
                    <nav className="sb-topnav navbar navbar-expand" style={{ backgroundColor: "#005fac", padding: "3.1rem .5rem" }}>
                        <img className="logo-panel mt-1 ml-0 " alt="Logo Panel" src={LogoPanel} />
                        <button className="btn btn-link btn-sm ml-2 " id="sidebarToggle" onClick={this.addSidebar} href="#"><FontAwesomeIcon icon={faBars} style={{ color: "white" }} title="Menu" /></button>
                        <div className="mt-0 d-none d-sm-none d-md-block div-vica-logo"><img className="logo-panel" alt="Logo Panel" src={vicaLogo} /></div>
                        <form className="d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                            <div className="input-group">
                                <div className="small mt-2 mr-3 text-white">Usuario: <strong> {localStorage.getItem("usuario") ? localStorage.getItem("usuario") : null}</strong>
                                    <p><strong> {localStorage.getItem("nombre") ? localStorage.getItem("nombre") + " " + localStorage.getItem("apellido") : null}</strong></p></div>
                            </div>
                        </form>
                    </nav>
                    <TabProvider defaultTab={this.SelectedTab()} vertical className="mt-1" >
                        <div id="layoutSidenav">
                            <div id="layoutSidenav_nav">
                                <nav className="sb-sidenav accordion" style={{ backgroundColor: 'white' }} id="sidenavAccordion">
                                    <div className="sb-sidenav-menu" style={{ border: "solid", borderColor: "#f4f4f4" }}>

                                        <div className="nav">
                                            <div className="sb-sidenav-menu-heading" ></div>

                                            <TabList>
                                            <ul className="list-unstyled components">
                                                 <li className="TabEst">
                                                    <Tab className="Tab" id="tabEst" tabFor="vertical-tab-one">Cuenta</Tab>
                                                </li>
                                                <li className="TabFact">
                                                    <Tab className="Tab" id="tabFacturas" tabFor="vertical-tab-two">Facturas</Tab>
                                                </li>
                                                <li className="TabPres">
                                                    <Tab className="Tab" id="tabPresupuesto" tabFor="vertical-tab-three">Presupuestos</Tab>
                                                </li>
                                                <li className="TabRecibo">
                                                    <Tab className="Tab" id="tabRecibo" tabFor="vertical-tab-four" >Recibos</Tab>
                                                </li>
                                                <li className="TabOT">
                                                    <Tab className="Tab" id="tabOT" tabFor="vertical-tab-five">Trabajos</Tab>
                                                </li>
                                           </ul>
                                        </TabList>
                                        </div>

                                    </div>
                                    <div className="sb-sidenav-footer sb-sidenav-light" >
                                        <label className="nav-link" style={{ cursor: "pointer" }} onClick={this.cerrarSesion}>
                                            <div className="sb-nav-link-icon" ><FontAwesomeIcon icon={faSignOutAlt} style={{ color: "#005fac" }} />
                                                <strong>  Cerrar Sesión</strong></div>
                                        </label>
                                    </div>
                                </nav>
                            </div>
                            <div id="layoutSidenav_content">
                                <main>
                                    <div className="container-fluid">

                                        <TabPanel tabId="vertical-tab-one">
                                            <EstadoCuenta />
                                        </TabPanel>

                                        <TabPanel tabId="vertical-tab-two">
                                            <Facturas />
                                        </TabPanel>

                                        <TabPanel tabId="vertical-tab-three">
                                            <Presupuestos />
                                        </TabPanel>
                                        <TabPanel tabId="vertical-tab-four">
                                            <Recibos />
                                        </TabPanel>
                                        <TabPanel tabId="vertical-tab-five">
                                            <OrdenesTrabajo />
                                        </TabPanel>


                                    </div>
                                </main>

                                <footer className="py-4 bg-light mt-auto">
                                <div className="container-fluid">
                                    <div className="d-flex align-items-center justify-content-between small">
                                        <div className="text-muted">Vica Neumaticos &copy; {moment().format("YYYY")} </div>
                                      
                                    </div>
                                </div>
                            </footer>

                            </div>

                        </div>
                    </TabProvider>
                </div>

            );
        }
    }
}



export default Menu;

