import React, { Component } from 'react';
//import de libreria para manejar fechas
import Moment from 'react-moment';
import moment from 'moment';

//Import para renderizar loading
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "./LoadingIndicator.js";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


//import de la URL de la api
import api from "./config/config.js";
//import para estilizar mensajes tipo NOTIFICACIONES
import {  toast } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-toastify/dist/ReactToastify.css';
//Import para convertir/descargar archivo excel
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

//Import para manejar el formato de los numeros
import NumberFormat from 'react-number-format';

import $ from "jquery"




class EstadoCuenta extends Component {
    constructor() {
        super();

        this.state = {
            fecha_hasta: moment().format('YYYY-MM-DD'),
            fecha_desde: moment().subtract(7, "days").format('YYYY-MM-DD'),
            items: [],
            pago: null,
            detalle: [],
            err: '',
            selected: [],
            mostrar: '',
            token: localStorage.getItem("token")
        }

        this.cambioFechaDesde = this.cambioFechaDesde.bind(this);
        this.cambioFechaHasta = this.cambioFechaHasta.bind(this);
    }


    cambioFechaHasta(e) {
        this.setState({
            fecha_hasta: e.target.value,
            err: ''
        })
    }


    cambioFechaDesde(e) {
        this.setState({
            fecha_desde: e.target.value,
            err: ''
        })
    }

    sumarImporte = () => {
        var total = 0
        if (this.state.items !== 0) {
            for (var i = 0; i < this.state.items.length; i++) {
                total = this.state.items[i].importe + total
            }
            return total
        } else { return total }
    }

    handleSubmit = async e => {
        e.preventDefault()

        if (this.state.fecha_desde === '') {
            this.setState({ err: "***Seleccione el campo Fecha Desde***" })
        } else if (this.state.fecha_hasta === '') {
            this.setState({ err: "***Seleccione el campo Fecha Hasta ***" })
        } else if (moment(this.state.fecha_desde).isAfter(this.state.fecha_hasta) === true) {
            this.setState({ err: "***El campo fecha desde es incorrecto***" })
        }
        else if (moment(this.state.fecha_hasta).isAfter(moment()) === true) {
            this.setState({ err: "***El campo fecha hasta es incorrecto***" })
        } else {
            var urlencoded = new URLSearchParams();
            urlencoded.append("fecha_desde", this.state.fecha_desde);
            urlencoded.append("fecha_hasta", this.state.fecha_hasta);
            try {
                let config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    body: urlencoded
                }
                let res = await trackPromise(fetch(`${api}estadocuenta/consultaestadocuenta`, config))
                let json = await res.json()
                if (json.estadocuenta.length === 0) {
                    this.notify()
                }
                else {

                    this.setState({
                        items: json.estadocuenta
                    })
                    console.log(this.state.items)
                    this.setState({ err: "" })
                    this.mostrarBoton()
                    this.mostrarLabel() 
                    return json;
                }
            } catch (error) {
                console.log(error)

            }
        }
    }

    notify = () => toast.info(' No hay Estados en esa fecha!', {
        position: "top-center",
        toastId: 9520,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: true,

    });



    ocultarBoton = () => {
       $(document).ready(function () {
            $(".botonExcel").hide();
        })
    }

    mostrarBoton = () => {
        $(document).ready(function () {
            $(".botonExcel").show();
        })
    }

    ocultarLabel = () => {
        $(document).ready(function () {
            $(".labelTotal").hide();
           
        })
    }

    mostrarLabel = () => {
        $(document).ready(function () {
            $(".labelTotal").show();
        })
    }

    archivoNombre = () => {
        return "EstadoCuenta de " + this.state.fecha_desde + ' ' + 'a ' + this.state.fecha_hasta
    }

    



    render() {
        return (
            <div className="BodyEstado">
                {this.ocultarBoton()}
                {this.ocultarLabel()}
                <div>
                    <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.10.21/css/jquery.dataTables.css"></link>
                       
                            <form onSubmit={this.handleSubmit}  >
                                <table className="Form-table">
                                    <tr>
                                        <td>  Desde:  </td>
                                        <td > <input id="fecha_desde" type="date" name="fecha_desde" value={this.state.fecha_desde} onChange={this.cambioFechaDesde} /></td>
                                        <td >  Hasta: </td>
                                        <td > <input id="fecha_hasta" type="date" name="fecha_hasta" value={this.state.fecha_hasta} onChange={this.cambioFechaHasta} /></td>
                                        <td><button id="BotonConsultar" onSubmit={this.handleSubmit} style={{ padding: 0, border: 0, background: "none" }} ><FontAwesomeIcon icon={faSearch} /> </button></td>
                                        <td><strong><label className="labelErr" style={{ color: "red" }} align="center">{this.state.err}</label></strong></td>
                                    </tr>
                                </table>
                            </form >
                            
                    <LoadingIndicator />
                    <div className="mt-5 ml-5  mr-5 mx-auto " style={{ maxWidth: "60%" }} >
                        <table className="table  table-striped table-border header-fixed" id="dataTable"  width="100%" cellspacing="0" >
                            <thead className="text-white text-center " style={{ backgroundColor: "#005fac" }}>
                                <tr >
                                    <th>Fecha</th>
                                    <th>Comprobante</th>
                                    <th>Importe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.items.map((item, i) =>
                                    {
                                        return(
                                        <tr key={i}>
                                            <td className="text-center">{!item.fecha ? "Saldo:" : <Moment format="DD/MM/YYYY">{item.fecha}</Moment>}</td>
                                            <td className="text-center">{item.comprobante}</td>
                                            <td className="text-center"><NumberFormat value={item.importe} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                        </tr>
                                    )
                                        })
                                }
                            </tbody>
                        </table>
                        <label className="labelTotal float-right mr-5"><strong>Total: <NumberFormat value={this.sumarImporte()} displayType={'text'} decimalScale={2} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></strong></label>
                        <div className="botonExcel">
                                <p title="Descargar Estado">
                                    <ReactHTMLTableToExcel
                                        title="Descargar Excel"
                                        id="test-table-xls-button"
                                        className="download-table-xls-button mx-auto d-block"
                                        table="dataTable"
                                        filename={this.archivoNombre()}
                                        sheet="EstadoDeCuenta"
                                        buttonText="" />
                                </p>
                        </div>

                    </div>
                </div>
            </div>
        );

    }

};


export default EstadoCuenta;