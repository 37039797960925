import React from 'react';
import ReactDOM from 'react-dom';
import {  Route, Switch, MemoryRouter } from 'react-router-dom';
//Estilo
import './App.css';
//Otros Sitios
import Login from "./Login.js"
import EstadoCuenta from "./EstadoCuenta.js"
/* import Consres from "./Consres.js"
import Constrab from "./Constrab.js"
import VerFacturas from "./VerFacturas.js"
import VerPresupuestos from "./VerPresupuestos.js"
//import MainMenu from "./MainMenu.js"
import NotFound from "./NotFound.js" */
import MainMenu from "./MainMenu.js"
import * as serviceWorker from './serviceWorker';

const App2 =  document.getElementById('root')
ReactDOM.render(
                    <MemoryRouter >
                      <Switch>
                          <Route exact path='/' component={Login}/>
                          <Route exact path='/Menu' component={MainMenu}/>
                      </Switch>
                  </MemoryRouter >
                  

, App2);

serviceWorker.unregister();
