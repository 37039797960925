import React, { Component } from 'react';
//import hoja de estilo
//import './Estilos/Consres.css';
//Import para renderizar loading
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "./LoadingIndicator.js";
//import de la URL de la api
import api from "./config/config.js"
//import libreria para manejar formato fecha
import Moment from 'react-moment';
import moment from 'moment';
//import para estilizar mensajes tipo NOTIFICACIONES
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Import para manejar el formato de los numeros
import NumberFormat from 'react-number-format';

//utilidades
import $ from "jquery"

//import de iconos
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";




class Presupuestos extends Component {
    constructor() {
        super();
        this.state = {
            fecha_hasta: moment().format('YYYY-MM-DD'),
            fecha_desde: moment().subtract(7, "days").format('YYYY-MM-DD'),
            items: [],
            detalle: null,
            err: "",
            presupuestosDistintos: [],
            selected: [],
            token: localStorage.getItem("token")

        }

        this.cambioFechaDesde = this.cambioFechaDesde.bind(this);
        this.cambioFechaHasta = this.cambioFechaHasta.bind(this);

    }//cierra el constructor


    cambioFechaHasta(e) {
        this.setState({
            fecha_hasta: e.target.value,
            err: ''
        })
    }



    cambioFechaDesde(e) {
        this.setState({
            fecha_desde: e.target.value,
            err: ''
        })
    }

    borrarDatos = () => {
        if (this.state.items !== [] || this.state.items !== "") {
            this.setState({ items: "", detalle: "", presupuestosDistintos: [], selected: [] })
        }
    }

    resaltarFila = () =>
        $('#tablePresupuesto tr').click(function (e) {
            $('#tablePresupuesto tr').removeClass('highlighted');
            $(this).addClass('highlighted');
        });

    handleSubmit = async e => {
        e.preventDefault()
        if (this.state.fecha_desde === '') {
            this.setState({ err: "***Seleccione el campo Fecha Desde***" })
        } else if (this.state.fecha_hasta === '') {
            this.setState({ err: "***Seleccione el campo Fecha Hasta***" })
        } else if (moment(this.state.fecha_desde).isAfter(this.state.fecha_hasta) === true) {
            this.setState({ err: "***El campo fecha desde es incorrecto***" })
        }
        else if (moment(this.state.fecha_hasta).isAfter(moment()) === true) {
            this.setState({ err: "***El campo fecha hasta es incorrecto***" })
        }
        else {
            this.borrarDatos()
            var urlencoded = new URLSearchParams();
            urlencoded.append("fecha_desde", this.state.fecha_desde);
            urlencoded.append("fecha_hasta", this.state.fecha_hasta);
            // urlencoded.append("fecha_desde", "2016-03-02");
            // urlencoded.append("fecha_hasta", "2020-04-04");
            try {
                let config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.state.token

                    },
                    body: urlencoded
                }
                let res = await trackPromise(fetch(`${api}presupuestos/consulta`, config))
                let json = await res.json()

                if (json.status === 500) {
                    this.setState({ err: json.message })

                } else if ($.isEmptyObject(json.presupuestos)) {
                    this.notify()
                    this.borrarDatos()
                }
                else {
                    this.setState({
                        items: json.presupuestos
                    })
                    this.setState({ err: "" })
                    this.resaltarFila()
                    return json;
                }
            } catch (error) {
                console.log(error)

            }
        }
    }
    notify = () =>
        toast.info('No hay movimientos en el rango de fecha indicado', {
            position: "top-center",
            toastId: "soyPresupuesto",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: true,
            pauseOnFocusLoss: true


        });



  /*   async componentDidMount() {
        convertToDataTable($('#tablePresupuesto'))
        convertToDataTable($('#DTdetallePresupuesto'))
    } */


    render() {

        return (
            <div >
                <div>
                    <form onSubmit={this.handleSubmit}  >
                        <table className="Form-table">
                            <tr>
                                <td>  Desde:  </td>
                                <td > <input id="fecha_desde" type="date" name="fecha_desde" value={this.state.fecha_desde} onChange={this.cambioFechaDesde} /></td>
                                <td >  Hasta: </td>
                                <td > <input id="fecha_hasta" type="date" name="fecha_hasta" value={this.state.fecha_hasta} onChange={this.cambioFechaHasta} /></td>
                                <td><button className="ml-2" id="BotonConsultar" onSubmit={this.handleSubmit} style={{ padding: 0, border: 0, background: "none" }} ><FontAwesomeIcon icon={faSearch} /> </button></td>
                                <td><strong><label className="labelErr" style={{ color: "red" }} align="center">{this.state.err}</label></strong></td>
                            </tr>
                        </table>
                    </form >
                    <LoadingIndicator />
                    <div className="tablaPresupuesto mx-auto mt-5" style={{ maxWidth: "90%" }} > {/* container width optional */}
                        <table className="table table-striped tablePresupuesto " id="tablePresupuesto" width="100%" cellspacing="10"> {/* any table */}
                            <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }}>
                                <tr>
                                    <th scope="col" className="text-center">Fecha</th>
                                    <th scope="col" className="text-center">Número</th>
                                    <th scope="col" className="text-center">Vehículo</th>
                                    <th scope="col" className="text-center">Total</th>
                                    <th scope="col" className="text-center">Vendedor</th>
                                    <th scope="col" className="text-center">Forma de pago</th>
                                    <th scope="col" className="text-center">Sucursal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.items ?
                                    this.state.items.map((item, i) =>
                                        <tr
                                            onClick={async () => {
                                                var urlencoded = new URLSearchParams();
                                                urlencoded.append("presupuesto_id", item.presupuesto_id);
                                                let config = {
                                                    method: 'POST',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-type': 'application/x-www-form-urlencoded',
                                                        'Authorization': 'Bearer ' + this.state.token
                                                    },
                                                    body: urlencoded
                                                }
                                                let res = await trackPromise(fetch(`${api}presupuestos/consultalinea`, config))
                                                let jsonLinea = await res.json();
                                                this.setState({ selected: jsonLinea.presupuestos_lineas })
                                                this.setState({ mostrar: item.nro_presupuesto })



                                            }

                                            }>

                                            <td className="text-center"><Moment format="DD/MM/YYYY HH:mm">{item.fechahora}</Moment></td>
                                            <td className="text-center">{item.nro_presupuesto}</td>
                                            <td className="text-center">{item.vehiculo}</td>
                                            <td className="text-center"><NumberFormat value={item.total} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                            <td className="text-center">{item.vendedor}</td>
                                            <td className="text-center">{item.nombre}</td>
                                            <td className="text-center">{item.sucursal}</td>
                                        </tr>
                                    )
                                    : null
                                }
                            </tbody>
                        </table>

                    </div>




                  <label align="center" style={{ marginLeft: "5%", marginTop:"1%" }}>Detalle del Prespuesto</label>


                    <div className="mt-4 ml-5 mx-auto" style={{ maxWidth: "90%"}}>
                        <table className="table table-striped" id="detallePresupuesto" style={{ width: "100%"}}> {/* any table */}
                            <thead className="text-white text-center" style={{ backgroundColor: "#005fac" }} >
                                <tr>
                                    <th className="text-center">#</th>
                                    <th className="text-center">Código </th>
                                    <th className="text-center">Descripción</th>
                                    <th className="text-center">P.U c/IVA</th>
                                    <th className="text-center">Desc/Rec</th>
                                    <th className="text-center">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody >
                                {this.state.selected.length > 0 ?
                                    this.state.selected.map((item, i) =>
                                        <tr>
                                            <td className="text-center">{item.cantidad}</td>
                                            <td className="text-center">{item.codigo_trabajo}</td>
                                            <td className="text-center">{item.descripcion}</td>
                                            <td className="text-center"><NumberFormat value={item.precio} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                            <td className="text-center"><NumberFormat value={item.descuento} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                            <td className="text-center"><NumberFormat value={item.subtotal} displayType={'text'} thousandSeparator={"."} prefix={'$'} decimalSeparator={","} /></td>
                                        </tr>
                                    )

                                    : null
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div >
        );
    }
};


export default Presupuestos;
